import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, Grid,IconButton } from "@material-ui/core"
// import {iframeSeguroVida,alignRigth} from './instruments/Style'
import CloseIcon from '@material-ui/icons/Close';
import { css } from '@emotion/react'
import DocumentoDeclaracion from '../../assets/documents/DocumentoDeclaracion.pdf'
// FINANCIERA COMPONENTS
import { ButtonDegrade } from '@findep/mf-landings-core'

const iframeSeguroVida= css`                        
            top: 12.5vh; 
            left: 12.5vw;
            width: 900px; 
            height: 480px; 
            border: none; 
            overflow: scroll;
            @media (max-width: 768px) {
                width: 250px;
                height: 380px;  
            }
            `
const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

const DialogGeneric = (props) => {
    const [disBtnSel, setDisBtnSel] = useState(true)    
    const [info, setInfo] = useState(false)    
    const [location, setlocation] = useState('') 
  
  useEffect(() => {         
    setInterval(function(){setInfo(true)},3000)  
    let  location= window.location.origin === 'http://localhost:8000' ? 'https://afi.tysonbeta.com' : window.location.origin  
    let  urlPdf=location+DocumentoDeclaracion
           
    let displayPdf=`https://docs.google.com/viewer?url=${urlPdf}&embedded=true`    
    
    // console.log("urlPdf=>",displayPdf)
    setlocation(displayPdf);

  }, []);
  
  const handleChangue=(event)=>{
    // props.iconClose()
    setDisBtnSel(false)   
    props.changueTask()  
  }
  
  return (
    <Dialog            
      open={props.open}      
      id='dialogo'
      maxWidth="xl"            
      >
          <DialogContent >
          <Grid
            container
            spacing={0}            
            alignItems="center"
            justifyContent="center"            
          >            
            <Grid container justify="center">
                <Grid item>
                    <div css={buttonCss} > 
                    {location && 
                        <iframe src={location}  css={iframeSeguroVida} rel="noopener noreferrer" frameborder="0"></iframe>
                    }   
                    {!location && 
                    <div><center><h4>Cargando... <br/> Por favor espere</h4></center></div>
                    }  
                    </div>
                </Grid>
            </Grid>                  

            <Grid container justify="center">
                <Grid item>
                    <div css={buttonCss} >
                        <ButtonDegrade
                        id="firma-continuar-solicitud-button"
                        // icon={ArrowForwardIcon}
                        textButton={props.lang ==="enUS"? "I accept" :"acepto"}
                        onClick={() => handleChangue()}
                        // disabled={this.state.next_firma}                        
                        /> 
                    </div>                   
                </Grid>                  
            </Grid>

        </Grid>
      </DialogContent>          
    </Dialog>
  );
};

export default DialogGeneric;
