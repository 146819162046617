import React from 'react';
import { css } from '@emotion/react'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';


export default function CheckboxGrl({
  onChange,
  title,
  alias,
  disabled
}) {
  const helpTxt = css` 
   margin: 0px 53px 15px 37px;
   color: #bf0000;
`

  const [state, setState] = React.useState({    
    checkedB: false,    
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    onChange(alias,event.target.checked)
  };

  return (
    <FormGroup row>      
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="primary"
            disabled={disabled}
          />
        }
        label={title}
      >
        </FormControlLabel> 
      {/* <FormHelperText css={helpTxt}>Lee aqui los términos y condiciones</FormHelperText>      */}
    </FormGroup>
  );
}